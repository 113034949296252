// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {  
    items: [
      {
        title: 'Overview',
        icon: 'la-home',
        page: '/auction/overview',
      },
      {
        title: 'Items',
        icon: 'la-television',
        page: '/auction/items',
      },
      {
        title: 'Invoices',
        icon: 'la-television',
        page: '/auction/invoices',
      },
      { section: 'SUPPORT', icon: 'la-ellipsis-h' },
      {
        title: 'Support',

        icon: 'la-support',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Changelog',
              icon: 'la-file',
              page: '/changelog',
              badge: { type: 'badge-danger', value: '3.4' },
            },
            {
              title: 'Raise Support',
              icon: 'la-support',
              page: 'https://pixinvent.ticksy.com/',
              isExternalLink: true
            },
          ]
        }
      }
    ]
  },
  vertical_menu: {
    items: [
      {
        title: 'Overview',
        icon: 'la-home',
        page: '/auction/overview',
      },
      // {
      //   title: 'Details',
      //   icon: 'la-cog',
      //   page: '/auction/details',
      // },
      // {
      //   title: 'Items',
      //   icon: 'la-television',
      //   page: '/auction/items',
      // },
      {
        title: 'Invoices',
        icon: 'la-edit',
        page: '/auction/invoices'
      },
      // { section: 'SUPPORT', icon: 'la-ellipsis-h' },
      // {
      //   title: 'Raise Support',
      //   icon: 'la-support',
      //   page: 'https://pixinvent.ticksy.com/',
      //   isExternalLink: true
      // }
    ]
  }

};





