import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';

import * as fromStore from '../_store/reducers'
import { login } from '../_store/actions/auth.actions';
import { AUTH_REQUEST_STATUS, getRequestState } from '../_store/reducers/auth.reducer';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = true;

  authSub!: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromStore.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2
  ) {
    // this.route.queryParams.subscribe(params => {
    //   this.returnUrl = params['returnUrl'];
    // });

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    
    this.authSub = this.store.select(getRequestState).subscribe((auth) => {
      console.log(auth);
      if (auth == AUTH_REQUEST_STATUS.AUTH_FAILURE) {
        this.alertService.error("Invalid Username or Password. Please check and try again");
        console.log("Invalid Username or Password. Please check and try again");
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const email = this.f['email'].value;
    const password = this.f['password'].value;

    this.store.dispatch(login({ email, password }));
    
  }
}
