import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStore from '../../../_store/reducers'
import { getUserProfile, ProfileState } from '../../../_store/reducers/profile.reducer';
import { logout } from '../../../_store/actions/auth.actions';


@Component({
  selector: 'app-full-layout-navbar',
  templateUrl: './full-layout-navbar.component.html',
  styleUrls: ['./full-layout-navbar.component.css']
})
export class FullLayoutNavbarComponent implements OnInit, OnDestroy {
  profileSub!: Subscription;
  profile!: ProfileState;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<fromStore.AppState>
  ) {}

  ngOnInit() {
    this.profileSub = this.store.select(getUserProfile).subscribe((profile) => {
      this.profile = profile;
    });
  }

  ngOnDestroy() {
    if(!this.profileSub.closed) {
      this.profileSub.unsubscribe();
      this.profile = undefined;
    }
  }

  doLogout() {
    this.store.dispatch(logout());
  }

  toggleNavbar(e) {
    const navbar = this.document.getElementById('navbar-mobile');
    if (navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    } else {
      navbar.classList.add('show');
    }
  }
  
}
