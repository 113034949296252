import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, take, tap } from 'rxjs/operators';

import { AuctionActions, AuthActions } from '../actions';
import { Store } from '@ngrx/store';

import * as fromStore from '../reducers'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { AuctionState } from '../reducers/auction.reducer';
import { AuctionService } from 'src/app/_services/auction.service';
import { getCurrenAuction } from '../reducers/profile.reducer';
import { getAuthenticated, isAuthenticated } from '../reducers/auth.reducer';

@Injectable()
export class AuctionEffects {
  auctionSub: Subscription | undefined;
  itemSub: Subscription | undefined;
  bidsSub: Subscription | undefined;
  currentAuction: string | undefined;
  auctionsSub: Subscription | undefined;
  invoiceSub: Subscription | undefined;

  constructor(
    private actions$: Actions,
    private router: Router,
    public afs: AngularFirestore,   // Inject Firestore service
    private store: Store<fromStore.AppState>,
    private auctionService: AuctionService
  ) {
    this.store.select(getCurrenAuction).subscribe((auction) => {
      if (this.currentAuction != auction) {
        this.currentAuction = auction;
        //console.log("[Auction.Effects] Auction State change detected");

        var authenticated = false;

        this.store.select(getAuthenticated).pipe(take(1)).subscribe((auth) => {
          authenticated = auth;
        });

        if (this.currentAuction && this.currentAuction != '') {
          this.store.dispatch(AuctionActions.load_auction({ uid: this.currentAuction }));
          if (authenticated) {
            this.router.navigate(['/auction/overview']);
          }
        }
        else {
          this.store.dispatch(AuctionActions.clear_auction());
          if (authenticated) {
            this.router.navigate(['/main/dashboard']);
          }
        }

        if (!authenticated) {
          this.router.navigate(['/login']);
        }
      }

    })
  }

  authenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticated),
      map((action) => {
        return AuctionActions.load_my_auctions({ user: action.uid })
        
      })
    )
  );

  invalidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.invalidate),
      tap(() => {
        if(this.auctionsSub && !this.auctionsSub.closed)
          this.auctionsSub.unsubscribe();
      }),
      map(() => {
        // console.log('[Profile.Effects] Auth Invalidation Detected');
        return AuctionActions.clear_my_auctions();
      })
    )
  );

  loadAuctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.load_my_auctions),
      tap((action) => {
        if(action['user']) {
          this.auctionsSub = this.auctionService.getAuctions(action['user']).subscribe((col : any) => {
            this.store.dispatch(AuctionActions.set_my_auctions({
              auctions: col.map((doc: any) => {
                return { uid: doc.payload.doc.id, ...doc.payload.doc.data() }
              })
            }));
          });
      }
      })
    ), { dispatch: false }
  );

  // public searchAuction(name: string, code: string) {
  //   return this.afs.collection<AuctionState>('auction', ref => {
  //     return ref
  //       .where('code', '==', code)
  //       .where('name', '==', name);
  //   });
  // }

  // public async submitAuctionNotFoundRequest(name: string, code: string) {
  //   await this.loadingCtrl.create().then((loader) => {
  //     // console.log('[Auth.Effects] Show loader');
  //     loader.present();

  //     // Collect the information for the support ticket

  //     this.afs.collection('support').add({ type: 'Auction Not Found', name: name, code: code, user_uid: this.afAuth.auth.currentUser.uid, user_email: this.afAuth.auth.currentUser.email, time: firestore.FieldValue.serverTimestamp() }).then(async () => {
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: "Support request submitted. Please hold tight while we look into your issue",
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     }).catch(async (error) => {
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: "An error occured while submitting the support request. Please ensure you are connected to the internet and try again.",
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     });
  //   });
  // }

  // public async startAuction() {
  //   await this.loadingCtrl.create().then((loader) => {
  //     // console.log('[Auth.Effects] Show loader');
  //     loader.present();
  //     const auctionRef: AngularFirestoreDocument<any> = this.afs.doc(`auction/${this.currentAuction}`);
  //     auctionRef.set({ state: 'In Progress' }, { merge: true }).then(() => {
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //     }).catch(async (error) => {
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: error,
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     });
  //   });
  // }

  // public async endAuction() {
  //   await this.loadingCtrl.create().then((loader) => {
  //     // console.log('[Auth.Effects] Show loader');
  //     loader.present();
  //     const auctionRef: AngularFirestoreDocument<any> = this.afs.doc(`auction/${this.currentAuction}`);
  //     auctionRef.set({ state: 'Finished' }, { merge: true }).then(() => {
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //     }).catch(async (error) => {
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: error,
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     });
  //   });
  // }

  // public async createItem(item) {
  //   await this.loadingCtrl.create().then((loader) => {
  //     // console.log('[Auth.Effects] Show loader');
  //     loader.present()
  //     const itemRef = this.afs.collection('items').ref.doc();
  //     const bidRef = this.afs.doc(`bids/${this.currentAuction}/items/${itemRef.id}`).ref;
  //     firestore().runTransaction(async (transaction) => {
  //       transaction.set(itemRef, { auction_id: this.currentAuction, ...item });
  //       transaction.set(bidRef, { winner: '', bidders: [] });
  //     }).then(function () {
  //       //console.log("[Auction.Effects] Item Successfully submitted");
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss()
  //     }).catch(async (error) => {
  //       // console.log("[Auction.Effects] Item Creation Failed");
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: error,
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     });
  //   });
  // }

  // public async updateItem(itemId, item) {
  //   await this.loadingCtrl.create().then((loader) => {
  //     const itemRef = this.afs.doc(`items/${itemId}`);
  //     itemRef.set(item, { merge: true }).then(() => {
  //       this.loadingCtrl.dismiss();
  //     }).catch(async (error) => {
  //       this.loadingCtrl.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: error.message,
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     })
  //   });
  // }

  // public async deletItem(itemId) {
  //   await this.loadingCtrl.create().then((loader) => {
  //     // console.log('[Auth.Effects] Show loader');
  //     loader.present()
  //     const itemRef = this.afs.doc(`items/${itemId}`).ref;
  //     const bidRef = this.afs.doc(`bids/${this.currentAuction}/items/${itemId}`).ref;
  //     firestore().runTransaction(async (transaction) => {
  //       transaction.delete(itemRef);
  //       transaction.delete(bidRef);
  //     }).then(function () {
  //       //console.log("[Auction.Effects] Item Successfully submitted");
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss()
  //     }).catch(async (error) => {
  //       // console.log("[Auction.Effects] Item Creation Failed");
  //       // console.log('[Auth.Effects] Hide loader');
  //       loader.dismiss();
  //       const alert = await this.alertCtrl.create({
  //         message: error,
  //         buttons: [{ text: 'Ok', role: 'cancel' }],
  //       })
  //       await alert.present();
  //     });
  //   });
  // }

  // public getBidDetails(item): Observable<any> {
  //   return this.afs.collection(`bids/${this.currentAuction}/items/${item.uid}/bids`, ref => {
  //     return ref
  //       .orderBy('time', "desc")
  //   }).valueChanges();
  // }

  // public async clearBids(items) {
  //   await this.loadingCtrl.create().then((loader) => {
  //     for (let item of items) {
  //       const itemBidRef = this.afs.doc(`bids/${this.currentAuction}/items/${item.uid}`).ref
  //       // TODO: Clean up sub-collections
  //       itemBidRef.set({ winner: '', bidders: [] }).then(() => {
  //       }).catch(async (error) => {
  //         this.loadingCtrl.dismiss();
  //         const alert = await this.alertCtrl.create({
  //           message: error.message,
  //           buttons: [{ text: 'Ok', role: 'cancel' }],
  //         })
  //         await alert.present();
  //       })
  //     }
  //     this.loadingCtrl.dismiss();
  //   });
  // }

  // public async submitBid(itemId: string, amount: number, uid: any) {
  //   await this.loadingCtrl.create().then((loader) => {
  //     // console.log('[Auth.Effects] Show loader');
  //     loader.present()
  //     this.store.select(fromStore.getUserProfile).pipe(take(1)).subscribe((user) => {
  //       const bidRef = this.afs.collection(`bids/${this.currentAuction}/items/${itemId}/bids`).ref.doc();
  //       const itemBidRef = this.afs.doc(`bids/${this.currentAuction}/items/${itemId}`).ref;
  //       //const itemRef = this.afs.doc(`items/${itemId}`).ref;

  //       return this.afs.firestore.runTransaction(async (transaction) => {
  //         return transaction.get(itemBidRef).then(async (itemDoc) => {
  //           if (!itemDoc.exists) {
  //             throw "Unable to find item for bid, please try again";
  //           }

  //           var current_bid = (itemDoc.data().current_bid ? itemDoc.data().current_bid : 0);
  //           if (amount > current_bid) {
  //             //transaction.update(itemRef, { current_bid: amount, winner: uid, bidders: firestore.FieldValue.arrayUnion(uid) });
  //             transaction.update(itemBidRef, { current_bid: amount, winner: uid, bidders: firestore.FieldValue.arrayUnion(uid) });
  //             transaction.set(bidRef, { item_id: itemId, amount: amount, user_id: uid, nickname: user.nickname, fullname: user.firstName + " " + user.lastName, email: this.afAuth.auth.currentUser.email, time: firestore.FieldValue.serverTimestamp() });
  //           }
  //           else {
  //             throw "Someone beat you to it. Please try again"
  //           }
  //         })
  //       }).then(function () {
  //         // console.log("[Auction.Effects] Bid Successfully submitted");
  //         // console.log('[Auth.Effects] Hide loader');
  //         loader.dismiss()
  //       }).catch(async (error) => {
  //         // console.log("[Auction.Effects] Bid Failed");
  //         // console.log('[Auth.Effects] Hide loader');
  //         loader.dismiss();
  //         const alert = await this.alertCtrl.create({
  //           message: error,
  //           buttons: [{ text: 'Ok', role: 'cancel' }],
  //         })
  //         await alert.present();
  //       });
  //     });
  //   });
  // }

  private subscribeToAuction(uid: string) {
    if (this.auctionSub && !this.auctionSub.closed) {
      this.auctionSub.unsubscribe();
    }
    console.log(uid);
    this.auctionSub = this.afs.doc<AuctionState>(`auction/${uid}`).valueChanges().subscribe((doc) => {
      console.log(doc);
      this.store.dispatch(AuctionActions.set_auction({ auction: { uid: uid, ...doc } }));
    });
  }

  private subscribeToItems(auction_id: string) {
    if (this.itemSub && !this.itemSub.closed) {
      this.itemSub.unsubscribe();
    }
    this.itemSub = this.afs.collection<AuctionState>('items', ref => {
      return ref
        .where('auction_id', '==', auction_id).orderBy("name")
    }).snapshotChanges().subscribe((col) => {
      this.store.dispatch(AuctionActions.update_items({
        items: col.map((doc) => {
          return { uid: doc.payload.doc.id, ...doc.payload.doc.data() }
        })
      }));
    });
  }

  private subscribeToBids(auction_id: string) {
    if (this.bidsSub && !this.bidsSub.closed) {
      this.bidsSub.unsubscribe();
    }
    this.bidsSub = this.afs.collection(`bids/${auction_id}/items`).snapshotChanges().subscribe((col) => {
      this.store.dispatch(AuctionActions.update_bids({
        bids: col.map((doc) => {
          return { item: doc.payload.doc.id, ...doc.payload.doc.data() as {} }
        })
      }));
    });
  }

  private subscribeToInvoices(auction_id: string) {
    if (this.invoiceSub && !this.invoiceSub.closed) {
      this.invoiceSub.unsubscribe();
    }
    this.invoiceSub = this.afs.collection<any>('invoices', ref => {
      return ref
        .where('auction_id', '==', auction_id).orderBy("email")
    }).snapshotChanges().subscribe((col) => {
      console.log("updating invoices");
      console.log(col);
      this.store.dispatch(AuctionActions.update_invoices({
        invoices: col.map((doc) => {
          return { uid: doc.payload.doc.id, ...doc.payload.doc.data() }
        })
      }));
    });
  }

  setAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.set_auction),
      tap((action) => {
        // console.log("[Auction.Effects] Auction Updated")
        this.subscribeToItems(action.auction.uid);
        this.subscribeToBids(action.auction.uid);
        this.subscribeToInvoices(action.auction.uid);
      })
    ), { dispatch: false }
  );

  loadAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.load_auction),
      tap((action) => {
        console.log("[Auction.Effects] Auction Loaded")
        console.log(action)
        this.subscribeToAuction(action.uid);
      })
    ), { dispatch: false }
  );

  clearAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.clear_auction),
      tap(() => {
        if (this.auctionSub && !this.auctionSub.closed) {
          this.auctionSub.unsubscribe();
        }
        if (this.itemSub && !this.itemSub.closed) {
          this.itemSub.unsubscribe();
        }
        if (this.invoiceSub && !this.invoiceSub.closed) {
          this.invoiceSub.unsubscribe();
        }
      })
    ), { dispatch: false }
  );
}