import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ProfileActions from '../actions/profile.actions';

export interface ProfileState {
  firstName: string | undefined;
  lastName: string | undefined;
  nickname: string | undefined;
  current_auction?: string;
}

export const initialState: ProfileState = {
    firstName: undefined,
    lastName: undefined,
    nickname: undefined
  };

export const userProfile  = (profile: ProfileState) => (profile);
export const currentAuction = (profile: ProfileState) => (profile.current_auction);

const profileReducer = createReducer(
initialState,
on(ProfileActions.set_profile,    (state, action) => ( action.profile )),
on(ProfileActions.update_profile, (state, action) => ({ ...state })),
on(ProfileActions.set_current_auction, (state, action) => ({ ...state })),
on(ProfileActions.clear_profile,   state          => ( initialState )),
);
  
export function profile_reducer(profile: ProfileState | undefined, action: Action) {
  return profileReducer(profile, action);
}

export const selectProfileState = createFeatureSelector<ProfileState>("profile");

// Profile Selectors
export const getUserProfile   = createSelector(selectProfileState, userProfile);
export const getCurrenAuction = createSelector(selectProfileState, currentAuction);