import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as AuctionActions from '../actions/auction.actions';
import { Timestamp } from '@firebase/firestore-types';

// export interface Auctions {
//   auctions: AuctionState[] | undefined
// }

export interface AuctionState {
  uid: string | undefined;
  name: string | undefined;
  description: string | undefined;
  start_time: Timestamp | undefined;
  end_time: Timestamp | undefined;
  logo: string | undefined;
  items: [ any ] | undefined;
  bids: [ any ] | undefined;
  invoices: [ any ] | undefined;
  administrators: [ string ] | undefined;
  state: string | undefined;
  win_message: string | undefined;
  lose_message: string | undefined;
}

export const AUCTION_STATUS = {
  UNKNOWN:    "UNKNOWN",
  NOTSTARTED: "Not Started",
  INPROGRESS: "In Progress",
  FINISHED:   "Finished"
}

export const auctionsInitialState: AuctionState[] = [];

export const initialState: AuctionState = {
  uid: undefined,
  name: undefined,
  description: undefined,
  start_time: undefined,
  end_time: undefined,
  logo: undefined,
  items: undefined,
  bids: undefined,
  invoices: undefined,
  administrators: undefined,
  state: AUCTION_STATUS.UNKNOWN,
  win_message: undefined,
  lose_message: undefined
  };

export const auction         = (auction: AuctionState) => (auction);
export const auctionItems    = (auction: AuctionState) => (auction.items);
export const auctionInvoices = (auction: AuctionState) => (auction.invoices);
export const itemBids        = (auction: AuctionState) => (auction.bids);
export const auctionDetails  = (auction: AuctionState) => ({ name: auction.name, description: auction.description, logo: auction.logo, start_time: auction.start_time, end_time: auction.end_time, state: auction.state, win_message: auction.win_message,  lose_message: auction.lose_message});
export const auctionItem     = (auction: AuctionState, props: any) => (auction.items ? auction.items.find(i => i.uid === props.itemId) : null);
export const itemBid         = (auction: AuctionState, props: any) => (auction.bids ? auction.bids.find(i => i.item === props.itemId) : null);
export const administrator   = (auction: AuctionState, props: any) => (auction.administrators && auction.administrators.find(i => i == props.userId) != undefined);
export const bidItems        = (auction: AuctionState, props: any) => (auction.items ? auction.items.filter(i => i != null && auction.bids && auction.bids.find((bid) => bid.item == i.uid && bid.bidders.find((j: any) => j == props.uid))): null);
export const winningItems    = (auction: AuctionState, props: any) => (auction.items ? auction.items.filter(i => i != null && auction.bids && auction.bids.find((bid) => bid.item == i.uid && bid.winner == props.uid)): null);
export const auctionState    = (auction: AuctionState) => (auction.state);
export const total           = (auction: AuctionState) => (auction.bids ? auction.bids.reduce((a, b) => +a + +(b.current_bid ? b.current_bid : 0), 0) : 0);

export const myAuctions = (auctions: AuctionState[]) => (auctions);

const auctionReducer = createReducer(
initialState,
on(AuctionActions.set_auction,     (state, action) => ( action.auction )),
on(AuctionActions.load_auction,    (state, action) => ( { ...state } )),
on(AuctionActions.clear_auction,    state           => ( initialState )),
on(AuctionActions.update_items,    (state, action) => ( { ...state, items: action.items } )),
on(AuctionActions.update_bids,     (state, action) => ( { ...state, bids: action.bids } )),
on(AuctionActions.update_invoices, (state, action) => ( { ...state, invoices: action.invoices } )),
on(AuctionActions.set_state,       (state, action) => ( { ...state, state: action.state }))
);

const auctionListReducer = createReducer(
  auctionsInitialState,
  on(AuctionActions.set_my_auctions,   (state, action) => ( action.auctions )),
  on(AuctionActions.load_my_auctions,  (state, action) => ({ ...state } )),
  on(AuctionActions.clear_my_auctions, (state, action) => ( auctionsInitialState ))
);
  
export function auction_reducer(auction: AuctionState | undefined, action: Action) {
  return auctionReducer(auction, action);
}

export function auction_list_reducer(auctions: AuctionState[] | undefined, action: Action) {
  return auctionListReducer(auctions, action);
}

export const selectAuctionState = createFeatureSelector<AuctionState>("auction");
export const selectMyAuctionsState = createFeatureSelector<AuctionState[]>("auctions");

// Auction Selectors
export const getAuction         = createSelector(selectAuctionState, auction);
export const getAuctionItems    = createSelector(selectAuctionState, auctionItems);
export const getAuctionInvoices = createSelector(selectAuctionState, auctionInvoices);
export const getAuctionDetails  = createSelector(selectAuctionState, auctionDetails);
export const getItem            = createSelector(selectAuctionState, auctionItem);
export const getBidItems        = createSelector(selectAuctionState, bidItems);
export const getWinningItems    = createSelector(selectAuctionState, winningItems);
export const getItemBids        = createSelector(selectAuctionState, itemBids);
export const getItemBid         = createSelector(selectAuctionState, itemBid);
export const getAuctionState    = createSelector(selectAuctionState, auctionState);
export const isAdministrator    = createSelector(selectAuctionState, administrator);
export const totalBids          = createSelector(selectAuctionState, total);

export const getMyAuctions      = createSelector(selectMyAuctionsState, myAuctions)