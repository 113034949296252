import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';

import * as AuthActions from '../actions/auth.actions';
import { AuthState } from '../../_models/auth.model';

export const AUTH_REQUEST_STATUS = {
  NONE:         "None",
  AUTHENTICATING:  "Authenticating",
  AUTH_FAILURE:    "Authentication Failure",
  AUTH_SUCCESS:    "Authentication Success",
  SIGNUP:          "Signup",
  SIGNUP_FAILURE:  "Signup Failure",
}

export const AUTHENTICATION_STATE = {
  UNAUTHENTICATED: "Unauthenticated",
  AUTHENTICATED:   "Authenticated"
}

export const initialState: AuthState = {
    request_state: AUTH_REQUEST_STATUS.NONE,
    authentication_state: AUTHENTICATION_STATE.UNAUTHENTICATED,
    uid: null,
    email: null
  };

export const isAuthenticated  = (auth: AuthState) => (auth.authentication_state == AUTHENTICATION_STATE.AUTHENTICATED);
export const requestState     = (auth: AuthState) => (auth.request_state);
export const authData         = (auth: AuthState) => (auth.uid, auth.email);
export const userId           = (auth: AuthState) => (auth.uid);

const authReducer = createReducer(
initialState,
on(AuthActions.login,           state           => ({ ...state, request_state: AUTH_REQUEST_STATUS.AUTHENTICATING})),
on(AuthActions.authenticated,   (state, action) => ({ ...state, request_state: AUTH_REQUEST_STATUS.NONE, authentication_state: AUTHENTICATION_STATE.AUTHENTICATED, uid: action.uid, email: action.email})),
on(AuthActions.invalidate,      state           => ({ ...state, request_state: AUTH_REQUEST_STATUS.NONE, authentication_state: AUTHENTICATION_STATE.UNAUTHENTICATED, uid: null, email: null })),
on(AuthActions.login_failed,    state           => ({ ...state, request_state: AUTH_REQUEST_STATUS.AUTH_FAILURE })),
on(AuthActions.login_success,   state           => ({ ...state, request_state: AUTH_REQUEST_STATUS.AUTH_SUCCESS })),
on(AuthActions.signup,          state           => ({ ...state, request_state: AUTH_REQUEST_STATUS.SIGNUP })),
on(AuthActions.signup_failed,   state           => ({ ...state, request_state: AUTH_REQUEST_STATUS.SIGNUP_FAILURE })),
on(AuthActions.signup_success,  state           => ({ ...state })),
on(AuthActions.forgot_password, state           => ({ ...state })),
);
  
export function auth_reducer(auth: AuthState | undefined, action: Action) {
  return authReducer(auth, action);
}

export const selectAuthState = createFeatureSelector<AuthState>("auth");

// Auth Selectors
export const getAuthData = createSelector(selectAuthState, authData);
export const getRequestState = createSelector(selectAuthState, requestState);
export const getAuthenticated = createSelector(selectAuthState, isAuthenticated);
export const getUserId = createSelector(selectAuthState, userId);