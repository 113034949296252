import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Console } from 'console';
import { AuctionState } from '../_store/reducers/auction.reducer';

@Injectable()
export class AuctionService {

    constructor(public afs: AngularFirestore) {
        
    }

    getAuctions(user_id: any) {
        return this.afs.collection<AuctionState>(`auction`, ref => ref.where('administrators', 'array-contains', user_id)).snapshotChanges();
    }
}