import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';

import { environment } from '../../../environments/environment';

import { AuthState } from '../../_models/auth.model';
import { auth_reducer } from './auth.reducer';
import { ProfileState, profile_reducer } from "./profile.reducer";
import { AuctionState, auction_list_reducer, auction_reducer } from "./auction.reducer";

export interface AppState {
  auth: AuthState,
  profile: ProfileState,
  auctions: AuctionState[],
  auction: AuctionState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: auth_reducer,
  profile: profile_reducer,
  auctions: auction_list_reducer,
  auction: auction_reducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];