import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import * as AuthActions from '../actions/auth.actions';

import { AuthState } from '../../_models/auth.model';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AuthState>,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        //console.log("[Auth.Effects] User State Authenticated");
        this.store.dispatch(AuthActions.authenticated({ uid: user.uid, email: user.email }));
      }
      else {
        //console.log("[Auth.Effects] User State Invalid");
        this.store.dispatch(AuthActions.invalidate());
        router.navigate
      }
    })
  }

  invalidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.invalidate),
      tap(() => {
        this.router.navigate(['/login']);
      }),
    ), { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(async (action: any) => {
        try {
          console.log(action.email);
          const result = await this.afAuth.signInWithEmailAndPassword(action.email, action.password);
          return AuthActions.login_success();
        } catch (error) {
          return AuthActions.login_failed();
        }
      }),
    ));

    logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        this.afAuth.signOut().then((result) => {
          // console.log('[Auth.Effects] Sign Out Success');
        }).catch((error) => {
          // console.log("[Auth.Effects] Sign Out Error");
        })
      }),
    ), { dispatch: false }
  );
}

