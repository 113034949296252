import { createAction, props } from '@ngrx/store';
import { AuctionState } from '../reducers/auction.reducer';

export const set_auction     = createAction('[Auction] Set Auction', props<{ auction: any }>());
export const load_auction    = createAction('[Auction] Load Auction', props<{ uid: string }>());
export const clear_auction   = createAction('[Auction] Clear Auction');
export const update_items    = createAction('[Auction] Update Items', props<{ items: any }>());
export const update_bids     = createAction('[Auction] Update Bids', props<{ bids: any }>());
export const update_invoices = createAction('[Auction] Update Invoices', props<{ invoices: any }>());
export const set_state       = createAction('[Auction] Set State', props<{ state: string }>());

export const load_my_auctions = createAction('[Auction] Get My Auctions', props<{ user: string }>());
export const set_my_auctions  = createAction('[Auction] Set My Auctions', props<{ auctions: AuctionState[] }>());
export const clear_my_auctions = createAction('[Auction] Clear My Auctions');