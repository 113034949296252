<nav
  class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-dark navbar-shadow">
  <div class="navbar-wrapper">
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs"
            [routerLink]="">
          <i class="feather ft-menu font-large-1"></i></a></li>
        <li class="nav-item"><a class="navbar-brand" [routerLink]="['/auction.overview']"><img class="brand-logo"
              alt="modern admin logo" src="../../../../assets/images/logo/logo.png">
            <h3 class="brand-text">Vendue </h3>
          </a></li>
        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse justify-content-end" id="navbar-mobile">
        <ul class="nav navbar-nav">
          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="true" class="mr-1 user-name text-bold-700">{{profile!.firstName}}</span>
              <span class="avatar avatar-online">
                <img src="../../../assets/images/portrait/small/default.png"
                  alt="avatar">
                <i></i>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <!-- <a class="dropdown-item" [routerLink]="['/user/user-profile']"><i class="feather ft-user"></i> Edit
                Profile </a>
              <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" [routerLink]="" (click)="doLogout()"><i class="feather ft-power"></i> Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
