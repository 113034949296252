import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, withLatestFrom, take } from 'rxjs/operators';

import { AuctionActions, AuthActions, ProfileActions } from 'src/app/_store/actions';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/_store/reducers'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { getCurrenAuction, ProfileState } from '../reducers/profile.reducer';
import { clear_auction } from '../actions/auction.actions';
import { arrayUnion, Firestore } from 'firebase/firestore';


@Injectable()
export class ProfileEffects {
  profileSub: Subscription | undefined;

  constructor(
    private actions$: Actions,
    private router: Router,
    public afs: AngularFirestore,   // Inject Firestore service
    private store: Store<fromStore.AppState>,
  ) { }

  private subscriptToProfileChanges(uid: string) {
    this.profileSub = this.afs.doc<ProfileState>(`users/${uid}`).valueChanges().subscribe((doc : any) => {
      this.store.dispatch(ProfileActions.set_profile({ profile: doc }));
      this.store.select(getCurrenAuction).pipe(take(1)).subscribe((auction) => {
      this.store.dispatch(ProfileActions.set_profile({ profile: doc }));
        if(auction != doc.current_auction)
          console.log("[Profile.Effects] Auction State change detected");
          if(doc.current_auction && doc.current_auction != '') {
            this.store.dispatch(AuctionActions.load_auction({ uid: doc.current_auction }));
          } else {
            this.store.dispatch(clear_auction());
          }
      });
    });
  }

  //Effects
  authenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticated),
      tap((action) => {
        this.subscriptToProfileChanges(action.uid);
        this.afs.doc<ProfileState>(`users/${action.uid}`).ref.get().then((doc : any) => {
          if(doc.data().current_auction && doc.data().current_auction != '') {
            this.router.navigate(['/auction/overview']);
          } else {
            this.router.navigate(['/main/dashboard']);
          }
        });
      })
    ), { dispatch: false }
  );

  invalidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.invalidate),
      tap(() => {
        if(this.profileSub && !this.profileSub.closed)
          this.profileSub.unsubscribe();
      }),
      map(() => {
        // console.log('[Profile.Effects] Auth Invalidation Detected');
        return ProfileActions.clear_profile();
      })
    )
  );

  setAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.set_current_auction),
      withLatestFrom(this.store),
      tap(([action, store]) => {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${store.auth.uid}`);
        if(action.uid) {
          userRef.set({ current_auction: action.uid, previous_auctions: arrayUnion(action.uid) }, {merge: true});
        } else {
          userRef.set({ current_auction: action.uid }, {merge: true});
        }
      }),
    ), { dispatch: false }
  );

  // updateProfile$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfileActions.update_profile),
  //     tap(async () => {
  //       // console.log('[Auth.Effects] Show loader');
  //       await this.loadingCtrl.create().then((loader) => loader.present());
  //     }),
  //     withLatestFrom(this.store),
  //     tap(([action, store]) => {
  //       const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${store.auth.uid}`);
  //       userRef.set(action.profile, { merge: true }).then(() => {
  //         // console.log('[Auth.Effects] Hide loader');
  //         this.loadingCtrl.dismiss();
  //       }).catch(async (error) => {
  //         // console.log("[Auth.Effects] Sign Up Error");
  //         // console.log('[Auth.Effects] Hide loader');
  //         this.loadingCtrl.dismiss();
  //         const alert = await this.alertCtrl.create({
  //           message: error.message,
  //           buttons: [{ text: 'Ok', role: 'cancel' }],
  //         })
  //         await alert.present();
  //       })
  //     }),
  //   ), { dispatch: false }
  // );
}